/* eslint-disable no-loop-func */
import React, { useState, useEffect, useRef,  forwardRef, useImperativeHandle } from 'react';
import { Context } from '../../../Context/AuthContext'
import $ from 'jquery';
import { Request } from '../../../scripts/Request';
import { HubObjects } from '../../../scripts/HubObjects';
import { Currency, UnCurrency, Uuid } from '../../../scripts/StringUtils';
import { UploadService } from '../../../scripts/UploadService';
import './index.css';



export const Camera = forwardRef((props, ref) => {
    const [viewLandMarks, setViewLandMarks] = useState(true);
    const [framesCount, setFramesCount] = useState(0);
  

    const frames = useRef([]);
    const socketRef = useRef(null);
    const connectionRef = useRef(null);
    const base64 = useRef("");
    const photoMode = useRef(false);
    const api = useRef("");

    let videoElement = null;
    let canvasElement = null;
    let canvasCtx = null;
    let bbElement = null;
    let bbCtx = null;

    let ignore_firts = 0;

    let image_index = 0;
    let op_srt = "55";



    useEffect(()=> {
        let wait_canvas = setInterval(()=> {
            videoElement = document.getElementsByClassName('input_video')[0];
            canvasElement = document.getElementsByClassName('output_canvas')[0];
            bbElement = document.getElementsByClassName('bb_canvas')[0];
            if(canvasElement) {
                clearInterval(wait_canvas);
                TryConnect();
                canvasCtx = canvasElement.getContext('2d');
                bbCtx = bbElement.getContext('2d');

                const camera = new window.Camera(videoElement, {
                    onFrame: async () => {
                        await faceMesh.send({ image: videoElement });
                    },
                    width: 480,
                    height: 480
                });
                camera.start();
            }
        }, 100);
    }, []);



    //#region Ref
    useImperativeHandle(ref, (args) => ({
        async AddFace(args) { return AddFace(args) },
        async PredictFace(args) { return PredictFace(args) },
        async TryConnect() { return TryConnect() },
        async Reset() { return Reset() },
        async PhotoMode(args) { photoMode.current = args },
        async UseApi(args) { api.current = args },
    }));
    //#endregion Ref





    //#region Results
    const onResults = async (results) => { 
        canvasCtx.save();
        canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
        canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height); 

        

        if(results.detections) {
            if(results.detections.length > 0) {
                window.drawRectangle(canvasCtx, results.detections[0].boundingBox,{color: 'blue', lineWidth: 4, fillColor: '#00000000'});
            }
        }


        if (results.multiFaceLandmarks) {
            for (const l of results.multiFaceLandmarks) {
                //op_srt = opacity.toString(16);
                //if(op_srt.length === 1)  op_srt = "0" + op_srt;
                
                if(viewLandMarks) {
                    window.drawConnectors(canvasCtx, l, window.FACEMESH_TESSELATION, { color: '#C0C0C0' + op_srt, lineWidth: 1 });
                    //window.drawConnectors(canvasCtx, l, window.FACEMESH_RIGHT_EYE, { color: '#FF3030' + op_srt});
                    //window.drawConnectors(canvasCtx, l, window.FACEMESH_RIGHT_EYEBROW, { color: '#FF3030' + op_srt });
                    //window.drawConnectors(canvasCtx, l, window.FACEMESH_RIGHT_IRIS, { color: '#0000FF' + op_srt });
                    //window.drawConnectors(canvasCtx, l, window.FACEMESH_LEFT_EYE, { color: '#30FF30' + op_srt });
                    //window.drawConnectors(canvasCtx, l, window.FACEMESH_LEFT_EYEBROW, { color: '#30FF30' + op_srt });
                    //window.drawConnectors(canvasCtx, l, window.FACEMESH_LEFT_IRIS, { color: '#0000FF' + op_srt });
                    //window.drawConnectors(canvasCtx, l, window.FACEMESH_FACE_OVAL, { color: '#E0E0E0FF' });
                    //window.drawConnectors(canvasCtx, l, window.FACEMESH_LIPS, { color: '#E0E0E0' + op_srt });

                    //opacity=opacity+5;
                    //if(opacity > 255) opacity = 0;
                    let cor;

                    for (let i = 0; i < l.length; i++) {
                        if (p([4, 10, 0, 152, 8, 21, 251, 34, 264, 129, 278, 58, 288, 33, 359, 57, 306, 173, 362, 172, 364], i)) {
                            //if (p([4, 129, 278], i)) {
                            cor = i === 10 || i === 152 || i === 34 || i === 264 ? "#FF0000" :  "#00FF00";
                            drawp(l[i].x * canvasElement.width, l[i].y * canvasElement.height, canvasCtx, cor, 3);  
                            //drawt(l[i].x * canvasElement.width, l[i].y * canvasElement.height, canvasCtx, '#000000', i);
                        }
                    }

                  

                    //drawPath([{x: (l[21].x * canvasElement.width), y: (l[21].y * canvasElement.height)}, {x: (l[251].x * canvasElement.width), y: (l[251].y * canvasElement.height)}], canvasCtx);
                    //drawPath([{x: (l[129].x * canvasElement.width), y: (l[129].y * canvasElement.height)}, {x: (l[4].x * canvasElement.width), y: (l[4].y * canvasElement.height)}, {x: (l[278].x * canvasElement.width), y: (l[278].y * canvasElement.height)}], canvasCtx);
                    
                    let margin = 15;
                    let p0 = {x: (l[34].x * canvasElement.width), y: (l[10].y * canvasElement.height)};
                    let p1 = {x: (l[264].x * canvasElement.width), y: (l[10].y * canvasElement.height)};
                    let p2 = {x: (l[264].x * canvasElement.width), y: (l[152].y * canvasElement.height)};
                    let p3 = {x: (l[34].x * canvasElement.width), y: (l[152].y * canvasElement.height)};

                    drawPath([
                        {x: p0.x-margin, y: p0.y-margin},
                        {x: p1.x+margin, y: p1.y-margin},
                        {x: p2.x+margin, y:p2.y+ margin},
                        {x: p3.x-margin, y: p3.y+margin},
                        {x: p0.x-margin, y: p0.y-margin}
                    ], canvasCtx);

                    //console.log(photoMode.current);
                 
                    let balance_point = {x: (l[10].x* canvasElement.width-l[152].x* canvasElement.width), y:(l[34].y* canvasElement.height-l[264].y* canvasElement.height), z:(l[10].z* canvasElement.height - l[152].z* canvasElement.height), margin: (l[34].x * canvasElement.width > 212-80 &&  l[264].x * canvasElement.width < 430-80 && l[10].y * canvasElement.height > 87 && l[152].y * canvasElement.height < 384), far: (dist2d(l[34], l[264]) > 178*0.94)};
                    try {
                        props.OnBalancePoint(balance_point);
                    } catch(e) {}


                  


                    if(ignore_firts > 5 
                        && dist2d(l[34], l[264]) > 178*0.94

                        && (Math.abs(l[10].x* canvasElement.width-l[152].x* canvasElement.width) < 6)
                        && (Math.abs(l[34].y* canvasElement.height-l[264].y* canvasElement.height) < 6)
                        && (Math.abs(l[10].z* canvasElement.height-l[152].z* canvasElement.height) < (photoMode.current?60:6)) //< usar 6~9 Proteção contra fotos

                        && l[34].x * canvasElement.width > 212-80 
                        && l[264].x * canvasElement.width < 430-80 
                        && l[10].y * canvasElement.height > 87
                        && l[152].y * canvasElement.height < 384
                        && Math.abs(l[21].y* canvasElement.height - l[251].y* canvasElement.height) < 9 && frames.current.length < 20)  {
                            drawp(balance_point.x*2+210-80-4, balance_point.z*2+55-4, canvasCtx, "#00FF00DD", 8);  

                            //#region Kernel v1
                            /*let ref = dist2d(l[34], l[264]);
                            let rate = (1 / ref);

                            let dist_testa_nariz = dist2d(l[10], l[4]) * rate;
                            let dist_nariz_boca = dist2d(l[4], l[0]) * rate;
                            let dist_boca_queixo = dist2d(l[0], l[152]) * rate;
                            let distlinha_boca = dist2d(l[58], l[288]) * rate;
                            let dist_max_eye = dist2d(l[33], l[359]) * rate;
                            let dist_min_eye = dist2d(l[173], l[362]) * rate;
                            let dist_nariz_largura = dist2d(l[129], l[278]) * rate;
                            let dist_testa_linha_eye = dist2d(l[10], l[8]) * rate;
                            let dist_linha_nariz = dist2d(l[8], l[4]) * rate;
                            
                            let testa = Area([l[10], l[251], l[8], l[21]], ref);
                            let total_right = Area([l[10], l[251], l[264], l[288], l[364], l[152]], ref);
                            let total_left = Area([l[152], l[172], l[58], l[34], l[21], l[10]], ref);
                            let high_face = Area([l[10], l[251], l[254], l[4], l[34], l[21]], ref);
                            let middle_face = Area([l[8], l[362], l[359], l[264], l[278], l[4], l[129], l[34], l[33], l[173]], ref);
                            let low_face = Area([l[4], l[278], l[288], l[364], l[152], l[172], l[58], l[129]], ref);
                            let mouth_space = Area([l[4], l[278], l[288], l[306], l[0], l[57], l[129]], ref);
                            let nose = Area([l[8], l[278], l[4], l[129]], ref);
                            let total = Area([l[10], l[251], l[264], l[288], l[364], l[152], l[172], l[58], l[34], l[21]], ref);
                            let min_eye = Area([l[33], l[8], l[359], l[4]], ref);
                            let max_eye = Area([l[173], l[8], l[362], l[4]], ref);

                            frames.current.push({total: total, total_right: total_right, total_left: total_left, testa: testa, high_face: high_face, middle_face: middle_face, low_face: low_face, mouth_space: mouth_space, nose: nose, min_eye: min_eye, max_eye: max_eye});
                            */
                            //#endregion Kernel v1

                            //#region Kernel v2
                            /*
                            let ref = dist2d(l[10], l[152]);
                            let ref1 = dist2d(l[34], l[264]);
                            let rate = (ref1 / ref);
                            let rat = ref;
        
                            let dist_testa_nariz = dist3d(l[10], l[4]) * rate;
                            let dist_nariz_boca = dist3d(l[4], l[0]) * rate;
                            let dist_boca_queixo = dist3d(l[0], l[152]) * rate;
                            let dist_linha_boca = dist3d(l[58], l[288]) * rate;
                            let dist_max_eye = dist3d(l[33], l[359]) * rate;
                            let dist_min_eye = dist3d(l[173], l[362]) * rate;
                            let dist_nariz_largura = dist3d(l[129], l[278]) * rate;
                            let dist_testa_linha_eye = dist3d(l[10], l[8]) * rate;
                            let dist_linha_nariz = dist3d(l[8], l[4]) * rate;
        
        
                            let testa = Area([l[10], l[251], l[8], l[21]], rat);
                            let total_right = Area([l[10], l[251], l[264], l[288], l[364], l[152]], rat);
                            let total_left = Area([l[152], l[172], l[58], l[34], l[21], l[10]], rat);
                            let high_face = Area([l[10], l[251], l[254], l[4], l[34], l[21]], rat);
                            let middle_face = Area([l[8], l[362], l[359], l[264], l[278], l[4], l[129], l[34], l[33], l[173]],rat);
                            let low_face = Area([l[4], l[278], l[288], l[364], l[152], l[172], l[58], l[129]], rat);
                            let mouth_space = Area([l[4], l[278], l[288], l[306], l[0], l[57], l[129]], rat);
                            let nose = Area([l[8], l[278], l[4], l[129]], rat);
                            let total = Area([l[10], l[251], l[264], l[288], l[364], l[152], l[172], l[58], l[34], l[21]],rat);
                            let min_eye = Area([l[33], l[8], l[359], l[4]], rat);
                            let max_eye = Area([l[173], l[8], l[362], l[4]], rat);
                            


                            //console.log(rate, dist_testa_nariz, dist_nariz_boca, dist_boca_queixo, dist_linha_boca);
                            //if (ignore_firts > 5) console.log(total, total_right, total_left, testa, high_face, middle_face, low_face, mouth_space, nose, min_eye, max_eye);
                            frames.current.push({total: total, total_right: total_right, total_left: total_left, testa: testa, high_face: high_face, middle_face: middle_face, low_face: low_face, mouth_space: mouth_space, nose: nose, min_eye: min_eye, max_eye: max_eye});
                            */
                            //#endregion Kernel v2


                            //#region Kernel v3
                            let ref = dist3d(l[10], l[152]);
                            let ref1 = dist3d(l[34], l[264]);
                            let rate = Math.pow(Math.pow(ref1, 3) / Math.pow(ref, 3), 0.3333333333);
                            //console.log(rate);

                            let dist_nariz = dist3d(l[4], l[8]) * rate;
                            let dist_left_eye = dist3d(l[4], l[173]) * rate;
                            let dist_right_eye = dist3d(l[4], l[362]) * rate;
                            let dist_testa_queixo = dist3d(l[10], l[152]) * rate;
                            let dist_linha_eye =  dist3d(l[34], l[264]) * rate;
                            let dist_nariz_l =  dist3d(l[4], l[58]) * rate;
                            let dist_nariz_r =  dist3d(l[4], l[288]) * rate;
                            let dist_boca = dist3d(l[57], l[306]) * rate;
                            let dist_boca_l = dist3d(l[21], l[0]) * rate;
                            let dist_boca_r = dist3d(l[251], l[0]) * rate;

                            let testa = dist_left_eye / dist_nariz;
                            let total_right = dist_right_eye / dist_nariz;
                            let total_left = dist_testa_queixo / dist_linha_eye;
                            let high_face = dist_nariz_l / dist_testa_queixo;
                            let middle_face = dist_nariz_l / dist_linha_eye;
                            let low_face = dist_nariz_r / dist_testa_queixo;
                            let mouth_space = dist_nariz_r / dist_linha_eye
                            let nose = dist_boca_l / dist_boca;
                            let total = dist_boca_r / dist_boca;
                            let min_eye = dist_boca_l / dist_linha_eye;
                            let max_eye = dist_boca_r / dist_linha_eye;

                            /*
                            let testa = Math.pow(dist_left_eye / dist_nariz, 2);
                            let total_right = Math.pow(dist_right_eye / dist_nariz, 2);
                            let total_left = Math.pow(dist_testa_queixo / dist_linha_eye, 2);
                            let high_face = Math.pow(dist_nariz_l / dist_testa_queixo, 2);
                            let middle_face = Math.pow(dist_nariz_l / dist_linha_eye, 2);
                            let low_face = Math.pow(dist_nariz_r / dist_testa_queixo, 2);
                            let mouth_space = Math.pow(dist_nariz_r / dist_linha_eye, 2);
                            let nose = Math.pow(dist_boca_l / dist_boca, 2);
                            let total = Math.pow(dist_boca_r / dist_boca, 2);
                            let min_eye = Math.pow(dist_boca_l / dist_linha_eye, 2);
                            let max_eye = Math.pow(dist_boca_r / dist_linha_eye, 2);
                            */

                            //console.log(rate, dist_testa_nariz, dist_nariz_boca, dist_boca_queixo, dist_linha_boca);
                            //if (ignore_firts > 5) console.log(total, total_right, total_left, testa, high_face, middle_face, low_face, mouth_space, nose, min_eye, max_eye);
                            frames.current.push({total: total, total_right: total_right, total_left: total_left, testa: testa, high_face: high_face, middle_face: middle_face, low_face: low_face, mouth_space: mouth_space, nose: nose, min_eye: min_eye, max_eye: max_eye});
                            //#endregion Kernel v3


                            setFramesCount(frames.current.length*5);
                            props.OnFrameCount(frames.current.length*5);


                            
                            if(frames.current.length === 20) {
                                canvasCtx.save();
                                canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
                                canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height); 
                              

                                bbCtx.save();
                                bbCtx.clearRect(0, 0, bbElement.width, bbElement.height);
                                bbCtx.drawImage(results.image, p0.x, p0.y, p1.x-p0.x, p2.y-p1.y, 0, 0, 96, 96);
                                base64.current = bbElement.toDataURL('image/jpeg', 0.8);
                                
                                try{
                                    props.OnComplete();  
                                } catch(e) {} 
                            }
                    } else {
                        drawp(balance_point.x*2+210-80-4, balance_point.z*2+55-4, canvasCtx, "#FF0000DD", 8);  
                    }
                    
                    ignore_firts++;
                }
            }
        }
        canvasCtx.restore();
    }
    //#endregion Results


    //#region Facemesh
    const faceMesh = new window.FaceMesh({
        locateFile: (file) => {
            //console.log(`https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`);
            return `js/${file}`;
        }
    });

    faceMesh.setOptions({
        selfieMode: true,
        maxNumFaces: 1,
        refineLandmarks: true,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5
    });
    faceMesh.onResults(onResults);



    //#region Image
    async function render(image) {
        await faceMesh.send({
            image: image
        });
    }

    

    /*const GetImage = () => {
        ignore_firts = 0;
        var image = new Image();
        image.crossOrigin = "anonymous";
        image.src = "dataset_raw/" + images[image_index];
       
        image.onload = async function () {
            canvasElement.width = image.width;
            canvasElement.height = image.height;
            console.log(image.src, canvasElement.width, canvasElement.height);
            for (let i = 0; i < 25; i++) {
                await render(image);
                //await sleep(100);
                ignore_firts++;
            }
            image_index++;
            if (image_index < images.length) GetImage();
        };
    }*/

    //GetImage();
    //#endregion Image
    //#endregion Facemesh


    //#region Connection
    const TryConnect = () => {
        socketRef.current = new WebSocket("ws://localhost:9001");
        socketRef.current.onopen = () => { 
            console.log("localhost success"); 
        };
        socketRef.current.onmessage = (msg)  => { 
            //console.log(msg.data)
            props.OnPredict(JSON.parse(msg.data));
        };
        socketRef.current.onclose = () => { 
            console.log("localhost closed"); 
        };
    }
    //#endregion Connection



    //#region Handlers
    const AddFace = (obj) => {
        if(api.current !== "") {
            Promise.resolve(new Request().Run(api.current + "?nocache=" + new Date().getUTCMilliseconds(), "POST", {cmd: "add_face", id: "", nome: obj.nome, arr: frames.current, base64: base64.current}, ""))
            .then((data) => {

            }).catch(() => {

            });
        } else {
            try {
                //console.log("{cmd:\"add_face\", id:\"\", nome:\"" + obj.nome+ "\", arr:" + JSON.stringify(frames.current) + ", base64:\"" + base64.current + "\" }");
                socketRef.current.send("{cmd:\"add_face\", id:\"\", nome:\"" + obj.nome + "\", arr:" + JSON.stringify(frames.current) + ", base64:\"" + base64.current + "\" }");
            } catch(e) { 
                console.log(e.message);
            } 
        }
    }

    const PredictFace = () => {
        if(api.current !== "") {
            Promise.resolve(new Request().Run(api.current + "?nocache=" +  new Date().getUTCMilliseconds(), "POST", {cmd: "predict_face", id: "", nome: "", arr: frames.current, base64: base64.current}, ""))
            .then((data) => {
                props.OnPredict(data);
            }).catch(() => {

            });
        } else {
            try {
                //console.log("{cmd:\"predict_face\", id:\"\", nome:\"\", arr:" + JSON.stringify(frames.current.slice(0, 20)) + ", base64:\"" + base64.current + "\" }");
                socketRef.current.send("{cmd:\"predict_face\", id:\"\", nome:\"\", arr:" + JSON.stringify(frames.current.slice(0, 20)) + ", base64:\"" + base64.current + "\" }");
            } catch(e) { }
        }
    }

    const Reset = () => {
        setFramesCount(0);
        props.OnFrameCount(0);
        frames.current = [];
    }
    //#endregion Handlers


    //#region Functions
    const drawt = (x, y, ctx, color, txt) => {
        ctx.font = '14px serif';
        ctx.fillStyle = color;
        ctx.fillText(txt, x, y);
    }

    const drawp = (x, y, ctx, color, line) => {
        ctx.beginPath();
        ctx.arc(x, y, line, 0, 2 * Math.PI, false);
        ctx.fillStyle = color;
        ctx.fill();
    }

    const drawPath = (points, context) => {
        context.beginPath();
        context.lineWidth = 2;
        points.map((item, i) => {
            if (i == 0) {
                context.moveTo(item.x, item.y);
            } else {
                context.lineTo(item.x, item.y);
            }
        });
        context.strokeStyle = '#ff000066';
        context.stroke();
    }

    const sleep = async (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const p = (arr, index) => {
        return arr.find(v => v == index) > -1
    }

    const dist2d = (p1, p2) => {
        return Math.sqrt(Math.pow((p1.x * canvasElement.width - p2.x * canvasElement.width), 2) + Math.pow((p1.y * canvasElement.height - p2.y* canvasElement.height), 2));
    }


    const dist3d = (p1, p2) => {
        return Math.sqrt(Math.pow((p1.x - p2.x), 2) + Math.pow((p1.y - p2.y), 2) + Math.pow((p1.z - p2.z), 2));
    }

    const distMatrix = (arr) => {
        let sum = 0;
        for (let i = 0; i < arr.length; i++) {
            sum += Math.pow(1 + arr[i], 2) * (i > 0 ? arr[i-1]: 1);
        }
        return sum;
    }


    const Output = (len, i) => {
        let arr = [];
        for (let j = 0; j < len; j++) {
            if (j == i) {
                arr.push(1);
            } else {
                arr.push(0);
            }
        }
        return arr.toString();
    }


    const Area = (vertices, ref = 1) => {
        var total = 0;

        for (var i = 0, l = vertices.length; i < l; i++) {
            var addX = vertices[i].x / ref;
            var addY = vertices[i == vertices.length - 1 ? 0 : i + 1].y / ref;
            var subX = vertices[i == vertices.length - 1 ? 0 : i + 1].x / ref;
            var subY = vertices[i].y / ref;

            total += (addX * addY * 0.5);
            total -= (subX * subY * 0.5);
        }

        return Math.abs(total);
    }
    //#endregion Functions


    return (
        <>
            <div className="camera_container">
                <video className="input_video" width="480px" height="480px"></video>
                <canvas className="output_canvas" width="480px" height="480px"></canvas>
                <img className="mask_canvas" src="/img/mask.png" alt="" />
            </div>

           
            <canvas className="bb_canvas" width="96px" height="96px"></canvas>
        </>
    )
});