import React, { useState, useEffect, useContext, useRef, lazy } from 'react';
import { useLocation } from "react-router-dom";

import { Context } from './Context/AuthContext';



export default function Layout({ children }) {
    const [isLogin, setIsLogin] = useState(true);
    const location = useLocation();
    const { loading, authenticated } = useContext(Context);
    const pathRef = useRef("");
     //#region Effects
     useEffect(() => {
        pathRef.current = location.pathname;
        setIsLogin(location.pathname.toLocaleLowerCase().indexOf("login") > -1 || location.pathname === "/");
    }, [location.pathname]);

    //#endregion Effects

    return (
        <>
            {children}
        </>
    )
}