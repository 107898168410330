import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext'
import $ from 'jquery';
import { Request } from '../../../scripts/Request';
import { HubObjects } from '../../../scripts/HubObjects';
import './index.css';
import { Camera } from '../Camera';



export default function Train() {
    const [framesCount, setFramesCount] = useState(0);
    const [nome, setNome]= useState("");
    const [message, setMessage]= useState("");
    const [balancePoint, setBalancePoint] = useState({x:0, y:0, z:0, margin: 0, far:0});

    const cameraRef = useRef();

    useEffect(()=> {
        cameraRef.current.PhotoMode(false);
        //cameraRef.current.UseApi("https://wijpxowuonhwxohejoynyknyve0nefkc.lambda-url.sa-east-1.on.aws/");
        cameraRef.current.UseApi("https://mediapipe.lifequery.org/api/FaceRecognition/Action");
    }, []);

    //#region Handlers
    const HandleSend = () => {
        try {
            cameraRef.current.AddFace({nome: nome});
        } catch(e) { }
    }


    const TryConnect = () => {
        cameraRef.current.TryConnect();
    }

    const Reset = () => {
        cameraRef.current.Reset();
        setNome("");
    }


    const Analyze = (p) => {
        //console.log(p);
        setBalancePoint(p);

        if(!p.margin) {
            setMessage("Matenha os pontos vermelhos dentro do espaço de seleção");
        } else if(!p.far) {
            setMessage("Aproxime o rosto da câmera");
        } else if(p.x < -6 ) {
            setMessage("Gire o rosto suavemente para a direita");
        } else if(p.x > 6 ) {
            setMessage("Gire o rosto suavemente para a esquerda");
        } else if(p.z > 6 ) {
            setMessage("Gire a cabeça suavemente para baixo");
        } else if(p.z < -6 ) {
            setMessage("Gire a cabeça suavemente para cima");
        }
    }
    //#endregion Handlers



    return (
        <>
            <div className="container">
                <div className="camera">
                    <Camera ref={cameraRef} OnFrameCount={setFramesCount} OnBalancePoint={Analyze}/>
                </div>

                <div className="percent">
                    {framesCount} %
                </div>

                <div className="message">
                   <span className={framesCount===100?'hide':''}> {message}</span> <span style={{display:'none'}}>{balancePoint.x.toFixed(2)}, {balancePoint.y.toFixed(2)}, {balancePoint.z.toFixed(2)}, {balancePoint.margin?1:0}, {balancePoint.far?1:0}</span>
                </div>
            </div>

             
            <center>
                <hr /> 


                <input className="nome" type="text" value={nome} onChange={(e) => setNome(e.target.value)} />
                <br />
                <button disabled={nome.length===0||framesCount<100} onClick={HandleSend}>Salvar face</button>
                {/*<button onClick={TryConnect}>TryConnect</button>*/}
                <button onClick={Reset}>Reset</button>
            </center>
           
        </>
    )
}