import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext'
import $ from 'jquery';
import { Request } from '../../../scripts/Request';
import { HubObjects } from '../../../scripts/HubObjects';
import './index.css';
import { Camera } from '../Camera';


export default function Home() {
    const [framesCount, setFramesCount] = useState(0);
    const [nome, setNome]= useState("");
    const [sum, setSum]= useState(1);
    const [predict, setPredict] = useState([]);
    const [balancePoint, setBalancePoint] = useState({x:0, y:0, z:0, margin: 0, far:0});
    const [config, setConfig] = useState({selfieMode: true});
    const [message, setMessage]= useState("");
    const cameraRef = useRef();
    const predictorRef = useRef([]);
  

    useEffect(()=> {
        cameraRef.current.PhotoMode(false);
        //cameraRef.current.UseApi("https://wijpxowuonhwxohejoynyknyve0nefkc.lambda-url.sa-east-1.on.aws/");
        cameraRef.current.UseApi("https://mediapipe.lifequery.org/api/FaceRecognition/Action");
    }, []);

    //#region Handlers
    const HandleSend = () => {
        try {
            cameraRef.current.PredictFace({nome: nome});
        } catch(e) { }
    }


    const TryConnect = () => {
        cameraRef.current.TryConnect();
    }

    const Reset = () => {
        cameraRef.current.Reset();
        predictorRef.current = [];
        setPredict([]);
    }


    const AddPredict = (arr) => {
        console.table(arr);
        arr = arr.filter((item) => { return item.diff <= 6 && item.err < 200});
        let sum = 0;
        arr.map((item, i) => {
            sum += Cost(item);
        });
        setSum(sum);

        /*
        let index = arr.findIndex(a => a.eigen === 1);
        if(index > -1){
            arr[index].count += 15;
        }*/

        arr = arr.sort((a,b) => (Cost(a) > Cost(b)) ? -1 : (Cost(a) < Cost(b)) ? 1 : 0);
        
        console.table(arr);

        const lowest = arr.reduce((previous, current) => {
            return Cost(current) < Cost(previous) ? current : previous;
        });

        //console.log(Cost(arr[0]), lowest.cosd);
        //if(Cost(arr[0]) < lowest.cosd) return;
        
        setPredict(arr);
        
    }

    const Analyze = (p) => {
        setBalancePoint(p);

        if(config.selfieMode) {
            if(!p.margin) {
                setMessage("Matenha os pontos vermelhos dentro do espaço de seleção");
            } else if(!p.far) {
                setMessage("Aproxime o rosto da câmera");
            } else if(p.x < -6 ) {
                setMessage("Gire o rosto suavemente para a direita");
            } else if(p.x > 6 ) {
                setMessage("Gire o rosto suavemente para a esquerda");
            } else if(p.z > 6 ) {
                setMessage("Gire a cabeça suavemente para baixo");
            } else if(p.z < -6 ) {
                setMessage("Gire a cabeça suavemente para cima");
            }
        }
    }
    //#endregion Handlers


    //#region Functions
    const Cost = (vec) => {
        /*
        let v1 = 1 + (vec.diff*+.1)*vec.err;
        let v2 = v1 / 10;

         return (vec.cosd)*v2;
        */
        
        let v1 = 1;
        let v2  = v1 / (((1+vec.err)*5) * (vec.diff*5+1) * (vec.cosd*5));
        
        return v2;
    }
    //#endregion Functions



    return (
        <>
            <div className="container">
                <div className="camera">
                    
                     <Camera ref={cameraRef} OnFrameCount={setFramesCount} OnComplete={HandleSend} OnPredict={AddPredict} OnBalancePoint={Analyze}/>
                   
                    
                </div>

                <div className="percent">
                    {framesCount} %
                </div>

                <div className="message">
                   <span className={framesCount===100?'hide':''}> {message}</span> <span style={{display:'none'}}>{balancePoint.x.toFixed(2)}, {balancePoint.y.toFixed(2)}, {balancePoint.z.toFixed(2)}, {balancePoint.margin?1:0}, {balancePoint.far?1:0}</span>
                </div>
            </div>
              
            <center>
                <hr />  

                {/*<button disabled={framesCount<100} onClick={HandleSend}>Predict face</button>
                <button onClick={TryConnect}>ConnectWS</button>*/}
                <button onClick={Reset}>Reset</button>
                <hr />  

                {
                    predict.length > 0 ? (
                        predict.map((item, i) => (
                        <div key={'predict'+i}>
                            {/*https://vcrecicla-cdn.s3.sa-east-1.amazonaws.com/db/faces/*/}
                            <img src={'https://mediapipe.lifequery.org/faces/'+item.img+'.jpg'} /> {item.img} / rank {predict.length===1?(1-(item.diff/11)).toFixed(3):(0+(Cost(item)/sum)).toFixed(12)}<br />
                        </div>
                        ))
                    ) :(<>Nenhum Resultado</>)
                }
           </center>
        </>
    )
}